<template>
  <v-autocomplete :dense="this.dense" :disabled="this.disabled" :items="users" :label="this.label"
                  :outlined="this.outlined"
                  :prepend-inner-icon="this.icon"
                  :rules="this.rules"
                  @change="change($event)"
                  item-text="fullname"
                  item-value="user_id" v-model="user_id">
    <template v-slot:item="{item}">
      <div class="widget-content p-0">
        <div class="widget-content-wrapper">
          <div class="widget-content-left mr-3">
            <v-avatar class="widget-content-left primary" size="40">
              <img :src="item.avatarURI" alt="" class="rounded-circle"
              >
            </v-avatar>
          </div>
          <div class="widget-content-left flex2">
            <div class="widget-heading">{{ item.fullname }}</div>
            <div class="widget-subheading opacity-7">{{ item.role }}</div>
          </div>
        </div>
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
import {ENDPOINTS} from "../../config";
import {Events} from "../../plugins/events";
import Template from "../settings/customerdisplay/Template";

export default {
  name: "Users",
  components: {Template},
  props: {
    filter: {
      type: String,
      default: ""
    },
    rules: Array,
    dense: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: "Select User"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    icon: String,
    user: Number
  },
  watch: {
    "user": function (val) {
      this.$emit('input', val);
    }
  },
  data: () => {
    return {
      //user_id: 0,
      users: []
    }
  },
  computed: {
    user_id: {
      get() {
        return this.user === undefined ? 0 : this.user
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  mounted() {
    this.loadUsers();
  },
  methods: {
    change(event) {
      this.$emit('input', event);
    },
    loadUsers() {
      this.axios.post(ENDPOINTS.SETTINGS.USER.GETALL + '?filter=' + this.filter).then((res) => {
        if (res.data.STATUS === "SUCCESS") {
          this.users = res.data.users;
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {

      })
    }
  }
}
</script>

<style scoped>

</style>