<template>
  <v-container fluid class="pa-0">
    <page-title
            :heading="$t('erp.lang_storageOverview') "
            :subheading="$t('erp.lang_storageOverview')"
      :icon="icon"
      show-previous-button
    ></page-title>
    <div class="app-main__inner">
      <StorageEdit />
    </div>
  </v-container>
</template>

<script>
//components
import PageTitle from "../../../../Layout/Components/PageTitle.vue";
import StorageEdit from "../../../../components/erp/storage/storageoverview/StorageEditComponent";

export default {
  components: {
    PageTitle,
    StorageEdit
  },
  data: () => ({
    icon: "pe-7s-box1 icon-gradient bg-tempting-azure"
  })
};
</script>
